.wrapper {
    width: 30px;
    margin: 0 5px;
    height: 100%;
}

.coverButton {
    width: 30px;
    height: 100%;
    text-decoration: none;
    background: no-repeat url(./albumCover.svg) center center;
}


.coverButton:hover, .coverButton:focus, .coverButton:active {
    background: no-repeat url(./albumCover-hover.svg) center center;
}
