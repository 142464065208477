/* 
 * droid serif
 */

/* regular */
@font-face {
    font-family: 'droid_serif';
    src: url('../assets/fonts/droid/droidserif-regular/DroidSerif-webfont.eot');
    src: url('../assets/fonts/droid/droidserif-regular/DroidSerif-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidserif-regular/DroidSerif-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidserif-regular/DroidSerif-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidserif-regular/DroidSerif-webfont.svg#droid_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* bold */
@font-face {
    font-family: 'droid_serif';
    src: url('../assets/fonts/droid/droidserif-bold/DroidSerif-Bold-webfont.eot');
    src: url('../assets/fonts/droid/droidserif-bold/DroidSerif-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidserif-bold/DroidSerif-Bold-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidserif-bold/DroidSerif-Bold-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidserif-bold/DroidSerif-Bold-webfont.svg#droid_serifbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

/* italic */
@font-face {
    font-family: 'droid_serif';
    src: url('../assets/fonts/droid/droidserif-italic/DroidSerif-Italic-webfont.eot');
    src: url('../assets/fonts/droid/droidserif-italic/DroidSerif-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidserif-italic/DroidSerif-Italic-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidserif-italic/DroidSerif-Italic-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidserif-italic/DroidSerif-Italic-webfont.svg#droid_serifitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

/* bolditalic */
@font-face {
    font-family: 'droid_serif';
    src: url('../assets/fonts/droid/droidserif-bolditalic/DroidSerif-BoldItalic-webfont.eot');
    src: url('../assets/fonts/droid/droidserif-bolditalic/DroidSerif-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidserif-bolditalic/DroidSerif-BoldItalic-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidserif-bolditalic/DroidSerif-BoldItalic-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidserif-bolditalic/DroidSerif-BoldItalic-webfont.svg#droid_serifbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}


/*
 * droid sans
 */

/* bold */
@font-face {
    font-family: 'droid_sans';
    src: url('../assets/fonts/droid/droidsans-bold/DroidSans-Bold-webfont.eot');
    src: url('../assets/fonts/droid/droidsans-bold/DroidSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidsans-bold/DroidSans-Bold-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidsans-bold/DroidSans-Bold-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidsans-bold/DroidSans-Bold-webfont.svg#droid_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

/* regular */
@font-face {
    font-family: 'droid_sans';
    src: url('../assets/fonts/droid/droidsans-regular/DroidSans-webfont.eot');
    src: url('../assets/fonts/droid/droidsans-regular/DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidsans-regular/DroidSans-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidsans-regular/DroidSans-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidsans-regular/DroidSans-webfont.svg#droid_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*
 * droid sans mono
 */

/* regular */
@font-face {
    font-family: 'droid_sans_mono';
    src: url('../assets/fonts/droid/droidsansmono-regular/DroidSansMono-webfont.eot');
    src: url('../assets/fonts/droid/droidsansmono-regular/DroidSansMono-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/droid/droidsansmono-regular/DroidSansMono-webfont.woff') format('woff'),
         url('../assets/fonts/droid/droidsansmono-regular/DroidSansMono-webfont.ttf') format('truetype'),
         url('../assets/fonts/droid/droidsansmono-regular/DroidSansMono-webfont.svg#droid_sans_monoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}




