.searchLabel {
    display: flex;    
    height: 36px;
    margin: 9px 10px;
    background: #313131;
    border-radius: 18px;
    padding-right: 18px;
    padding-left: 5px;
    border: 1px solid #000;
}

.searchLabel input {
    margin: 0;
    background-color: inherit;
    border: none;
    color: #575757;
}

.searchLabel input::placeholder {
    color: #000;
}

.searchLabel input:hover, .searchLabel input:focus {
    border: none;
    box-shadow: none;
}

.magnifying {
    display: inline-flex;
    width: 36px;
    background: no-repeat center url('./magnifying.svg');
}

@media screen and (max-width: 800px) {
    .search {
        flex-grow: 10;
    }
}
