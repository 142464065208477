.list li {
  border-bottom: 1px solid #585858;
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.list li:last-child {
  border: none;
}

.count {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  color: #222;
  background: #585858;
  border-radius: 100px;
  padding: 3px 6px;
  margin: 0 10px;
  min-width: 22px;
  box-sizing: border-box;
  text-align: center;
}

.buttonsWrapper {
  margin-left: auto;
}

button {
  background: none;
  border: none;
  padding: 1px 6px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #222;
}

button:hover,
button:focus {
  color: #000;
}
