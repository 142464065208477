

.accessibilityText {
    position: absolute;
    left: -200000px;
    color: transparent;
    height: 0;
    width: 0;
    display: block;
}
