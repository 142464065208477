.detail {
   margin: 0 0 34px 0; 
   min-height: 80px;
}


.detail h2 {
    font-size: 24px;
    font-size: 2.4rem;
    color: #000;
    vertical-align: top;
    display: inline-block;
    margin: 0px 3px 3px;
}


.detail img {
    float: left;
    width: 80px;
    height: 80px;
    margin: 0 10px 10px 0;
    border-radius: 2px;
    box-shadow: 0 0 6px #000;
}

.detail img:hover, .detail img:focus {
    box-shadow: 0 0 10px #000;
}

.email {
    color: var(--link);
    display: flex;
    align-items: center;
}

.verified {
    height: 22px;
    width: auto;
    font-size: 20px;
    font-size: 2.0rem;
    margin: 0 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.verified a {
    text-decoration: none;
}

.adminButtons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 24px;
    margin-bottom: 2.4rem;
}

.adminButtons button {
    display: inline-flex;
    font-weight: normal;
    margin: 0;
}
