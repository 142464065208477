.wrapper {
    width: 60px;
    height: 100%;
}

.plusButton {
    background: no-repeat url(./plus.svg) center center;
    width: 60px;
    height: 100%;
    text-decoration: none;
}

.plusButton:hover, .plusButton:focus, .plusButton:active {
    background-image: url(./plus-hover.svg);
}
