.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    /*background-color: rgba(0,0,0,.8); */
    background-color: rgba(49,49,49,.8);
    z-index: 999;
}

.overlay article {
    max-width: 770px;
    margin: 50px auto;
}
