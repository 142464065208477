.wrapper {
    width: 30px;
    margin: 0 5px;
    height: 100%;
}

.deleteButton, .deleteButtonActive {
    width: 30px;
    height: 100%;
    text-decoration: none;
}

.deleteButton {
    background: no-repeat url(./deletePhoto-inactive.svg) center center;
}

.deleteButtonActive:hover, .deleteButtonActive:focus, .deleteButtonActive:active {
    background: no-repeat url(./deletePhoto-hover.svg) center center;
}

.deleteButtonActive {
    background: no-repeat url(./deletePhoto.svg) center center;
}
