.albums {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    margin-top: 40px;
}

.album {
    display: inline-block;
    vertical-align: top;
    width: 90px;
    height: 90px;
    margin: 0;
    flex: none;
}

.album a {
    display: block;
    width: 90px;
    height: 90px;
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    background: url(./dia.svg) no-repeat center center;
    background-color: #272727;
    background-size: 90px auto;
}

.album a:hover, .album a:active, .album a:focus {
    background-color: #313131;
}

.album img {
    object-fit: cover;
    height: 88px;
    width: 88px;
}

.album a:hover img, .album a:active img, .album a:focus img {
    filter: brightness(110%);
}

