.photoInfo {
  background: #222;
  border-top: 1px solid #000;
  color: #a0a0a0;
  padding-bottom: 55px; /* same as rating height */
}

.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 30px;
}

.infoGroup {
  border: 1px solid #1a1a1a;
  margin: 3px 0;
  padding: 8px 30px;
  background: #272727;
  line-height: 34px;
  line-height: 3.4rem;
  border-radius: 3px;
}

.exposure {
  display: flex;
  justify-content: space-evenly;
}

.fnum {
  font-family: var(--serif);
  font-size: 18px;
}

.photoInfo label {
  color: #a0a0a0;
  width: 100px;
  font-weight: normal;
  vertical-align: top;
  margin-top: 16px;
}

.form label {
  display: inline-block;
}

.form input {
  display: inline-block;
  width: calc(100% - 100px);
}

.photoInfo input {
  padding: 8px 12px;
  background: #3a3a3a;
  border: 1px solid #000;
  color: #ccc;
}

.form textarea {
  background: #3a3a3a;
  color: #ccc;
  margin: 0;
  border: 1px solid #000;
}

.textareaWrapper {
  margin-top: 5px;
  display: inline-block;
  box-sizing: border-box;
  width: calc(100% - 100px);
}

.photoInfo ::placeholder {
  color: #000;
  font-style: italic;
}

.formWrapper {
  margin-bottom: 40px;
}
