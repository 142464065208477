.modalFooter {
    padding: 0px 60px 60px;
    margin-top: 40px;
    text-align: center;
}

.modalFooter input[type="submit"] {
    margin-right: auto;
    margin-left: auto;
    max-width: 648px;
}

@media (max-width: 800px) {
    /* We use less padding on small screens (phones) */
    .modalFooter {
        padding: 30px 10px 20px;
    }
}
