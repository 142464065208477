.errorsList li {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 6px 12px;
    min-height: 34px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 2px;
    color: var(--red);
    border: 1px solid var(--red);
    /*background: var(--trans-red);*/
    background: rgba(255, 226, 226, 0.8);
}
