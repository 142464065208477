.button {
    height: 38px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    font-size: 16px;
    font-size: 1.6rem;
    vertical-align: middle;
    width: 100%;
    padding: 0 23px;
    margin: 5px 0 0 0;
    text-align: center;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 2px;
    color: #ccc;
    background: linear-gradient(#404040, #222);
    box-sizing: border-box;
}

a.button, a.button:visited {
    color: #ccc;
    text-decoration: none;
    cursor: pointer;
}

.button:hover, .button:focus, .button:active {
    color: #fff;
    box-shadow: 0 0 3px #000;
    text-decoration: none;
}

.button[disabled] {
    opacity: 0.7;
    color: var(--disabled-input-color);
    cursor: not-allowed;
}

.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active {
    color: var(--disabled-input-color);
    box-shadow: none;
}

.primary {
    background: linear-gradient(#404040, #222);
    font-weight: bold;
    font-size: 14px;
    font-size: 1.4rem;
}

.primary[disabled] {
    opacity: 1;
    cursor: not-allowed;
    background: linear-gradient(#4f4f4F, #313131);
}

