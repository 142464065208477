.mainWrapper {
    margin: 30px 0;
    position: relative;
    text-align: center;
}


.lightboxLink {
    display: inline-block;
    margin: 0 auto;
    position: relative;
}

.image {
    max-height: calc(100vh - 150px); /* 56 + 32 + 2*30 + 2 */
    max-width: calc(100vw - 100px);
    width: auto;
    height: auto;
    margin: 0;
    display: block;
}

.prev, .next {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.prev {
    right: 50%;
    background: no-repeat url(./arrow-left.svg);
    background-position: left 15px center;
}

.next {
    left: 50%;
    background: no-repeat url(./arrow-right.svg);
    background-position: right 15px center;
}

.prev:hover {
    background-image: url(./arrow-left-hover.svg);
}

.next:hover {
    background-image: url(./arrow-right-hover.svg);
}

.lightboxLink:hover img {
    filter: brightness(110%);
}



