.bar {
    height: 30px;
    color: var(--header-color);
    background: var(--header-bg);
    border-bottom: 1px solid #131313;
    border-top: 1px solid #131313;
    display: flex;
    align-items: center;
    top: 55px;
    left: 0px;
    right: 0px;
    position: fixed;
    z-index: 3;
    padding-right: 10px;
}

.count {
    font-size: 12px;
    font-size: 1.2rem;
    color: #919191;
    margin: 0 auto;
    font-weight: bold;
}

.done {
    font-size: 15px;
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
    background: var(--header-bg);
    min-width: 60px;
    padding: 0 10px;
    margin-right: 10px;
}
.done:hover, .done:focus, .done:active {
    color: #CCC
}
