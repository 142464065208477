.wrapper {
  position: relative;
}

.wrapper textarea {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  resize: none;
  margin: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  letter-spacing: normal;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  min-height: 80px;
  padding: 8px 12px;
  line-height: normal;
}

.spring {
  opacity: 0;
  margin: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  letter-spacing: normal;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  border: 1px solid transparent;
  min-height: 80px;
  padding: 8px 12px;
  line-height: normal;
}
