.wrapper {
    width: 60px;
    height: 100%;
}

.menuButton {
    background: no-repeat url(./menu.svg) center center;
    width: 60px;
    height: 100%;
    text-decoration: none;
}

.menuButton:hover, .plusButton:focus, .plusButton:active {
    background-image: url(./menu-hover.svg);
}
