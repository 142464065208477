.headerLinks {
    display: flex;
    margin: 0 0 0 auto;
}

.headerLinks a {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    height: var(--header-height);
    padding: 0px 25px;
    color: var(--header-color);
}

.active {
    background: var(--trans-white);
}


