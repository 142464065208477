@import-normalize; /* bring in normalize.css styles */

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,400italic);


:root {
    /* Colors */
    --red: #970000;
    --green: #009700;
    --trans-red: rgba(150, 0, 0, 0.08);
    --trans-white: rgba(255, 255, 255, 0.1);
    --background: #313131;
    --link: #222;
    --link-hover: #000;
    --link-active: #000;
    --link-focus: #000;
    --link-visited: #000;
    --header-bg: #222;
    --header-color: #fff;
    --input-bg: #585858;
    --input-border: #313131;
    --input-color: #000;
    --disabled-input-bg: #585858;
    --disabled-input-color: #222;
    --article-bg: #666;
    --article-border: #222;
    --title-color: #222;


    /* Sizes */
    --header-height: 55px;
    --nav-height: 32px;



    /* Fonts */
    --serif: droid_serif, Georgia, "DejaVu Serif", Norasi, serif;
    --sans: droid_sans, Verdana, Helvetica, sans-serif;
    --mono: droid_sans_mono, "Lucida Console", Monaco, monospace;
}

html {
    margin: 0;
    height: 100%;
    font-size: 62.5%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    background-color: var(--background);
    font-family: var(--sans);
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1em;
}

/* to avoid blank space at end of page */
#root {
    padding-bottom: 1px;
}

/* Paragraphs */
p {
    margin: 0.5em 0;
}

/* Emphasis */
em {
    font-style: italic;
}

strong {
    font-weight: bold;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Links */
a {
    color: var(--link);
    text-decoration: underline;
}
a:hover {
    color: var(--link-hover);
}
a:focus {
    color: var(--link-focus);
}
a:active {
    color: var(--link-active);
}
/*a:visited {
    color: var(--link-visited);
}*/


/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 0.8rem;
    color: var(--title-color);
}

h1 {
    font-size: 24px;
    font-size: 2.4rem;
}

h2 {
    font-size: 22px;
    font-size: 2.2rem;
}

h3 {
    font-size: 20px;
    font-size: 2rem;
}

h4 {
    font-size: 18px;
    font-size: 1.8rem;
}

h5 {
    font-size: 16px;
    font-size: 1.6rem;
}

#content {
    padding-bottom: 50px;
}

#navigationContent {
    padding-top: var(--nav-height);
}

article {
    background: var(--article-bg);
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    padding: 20px 0;
}


hr {
    margin: 0 15px 5px 10px;
    height: 0px;
    border: none;
    border-top: 1px solid #313131;
    border-bottom: 1px solid #7a7a7a;
}

.columned178 {
    margin: 0 auto 50px;
    padding-bottom: 50px;
}

@media screen and (min-width:1px) {
    .columned178 {
        width: 178px; /* 1 column */
    }
}

@media screen and (min-width:386px) {
    .columned178 {
        width: 356px; /* 2 columns */
    }
}

@media screen and (min-width:564px) {
    .columned178 {
        width: 534px; /* 3 columns */
    }
}

@media screen and (min-width:742px) {
    .columned178 {
        width: 712px; /* 4 columns */
    }
}

@media screen and (min-width:920px) {
    .columned178 {
        width: 890px; /* 5 columns */
    }
}

@media screen and (min-width:1098px) {
    .columned178 {
        width: 1068px; /* 6 columns */
    }
}

@media screen and (min-width:1276px) {
    .columned178 {
        width: 1246px; /* 7 columns */
    }
}

@media screen and (min-width:1454px) {
    .columned178 {
        width: 1414px; /* 8 columns */
    }
}

@media screen and (min-width:1632px) {
    .columned178 {
        width: 1602px; /* 9 columns */
    }
}

@media screen and (min-width:1810px) {
    .columned178 {
        width: 1780px; /* 10 columns */
    }
}

@media screen and (min-width:1988px) {
    .columned178 {
        width: 1958px; /* 11 columns */
    }
}

@media screen and (min-width:2166px) {
    .columned178 {
        width: 2136px; /* 12 columns */
    }
}

@media screen and (min-width: 2344px) {
    .columned178 {
        width: 2314px; /* 13 columns */
    }
}

@media screen and (min-width: 2522px) {
    .columned178 {
        width: 2492px; /* 14 columns */
    }
}





.columned250 {
    margin: 0 auto 50px;
    padding-bottom: 50px;
}

@media screen and (min-width:280px) {
    .columned250 {
        width: 250px; /* 1 column */
    }
}

@media screen and (min-width:530px) {
    .columned250 {
        width: 500px; /* 2 columns */
    }
}

@media screen and (min-width:780px) {
    .columned250 {
        width: 750px; /* 3 columns */
    }
}

@media screen and (min-width:1030px) {
    .columned250 {
        width: 1000px; /* 4 columns */
    }
}

@media screen and (min-width:1280px) {
    .columned250 {
        width: 1250px; /* 5 columns */
    }
}

@media screen and (min-width:1530px) {
    .columned250 {
        width: 1500px; /* 6 columns */
    }
}

@media screen and (min-width:1780px) {
    .columned250 {
        width: 1750px; /* 7 columns */
    }
}

@media screen and (min-width:2030px) {
    .columned250 {
        width: 2000px; /* 8 columns  */
    }
}

@media screen and (min-width:2280px) {
    .columned250 {
        width: 2250px; /* 9 columns */
    }
}

@media screen and (min-width:2530px) {
    .columned250 {
        width: 2500px; /* 10 columns */
    }
}








