.photoWrapper {
    width: 236px;
    height: 236px;
    margin: 14px 7px 0 7px;
    display: inline-block;
    position: relative;
    transition: margin 0.5s ease;
}

.photo {
    margin: 0;
    padding: 0;
    position:relative;
    box-sizing: border-box;
    border: none;
    padding: 10px;
    overflow: hidden;
}

.selected {
    background: #CCC;
}

.dragPin {
    opacity: 0.5;
}

.photoRightOver {
    margin-right: 257px;
}

.photoLeftOver {
    margin-left: 257px;
}

.imgWrapper {
    position: relative;
    box-sizing: border-box;
    width: 216px;
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgWrapper img {
    max-height: 216px;
    max-width: 216px;
    width: auto;
    height: auto;
    display: block;
    border: 1px solid #222;
    box-sizing: border-box;
}

.basketRight, .basketLeft, .basketRightOver, .basketLeftOver {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    bottom: 0;
}

.basketRight {
    left: 50%;
}

.basketLeft {
    right: 50%;
}

.basketRightOver {
    width: 382px;
    left: 50%;
}

.basketLeftOver {
    width: 382px;
    right: 50%;
}
