.abstract {
    display: inline-block;
    vertical-align: top;
    width: 150px;
    margin: 28px 14px 0 14px;
}

.abstract a {
    display: block;
    width: 150px;
    height: 150px;
    background-color: #474747;
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    overflow: hidden;
}

.abstract a:hover, .abstract a:active, .abstract a:focus {
    background-color: #505050;
}

.abstract h6 {
    margin: 10px 0 0 0;
    color: #131313;
    text-align: center;
    margin-bottom: 14px;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.abstract img {
    object-fit: cover;
    height: 150px;
    width: 150px;
}

.abstract a:hover img, .abstract a:active img,
.abstract a:focus img {
    filter: brightness(110%);
}

.allPhotos {
    background: url(./dia.svg) no-repeat center center;
}

.folder {
    background: url(./folder.svg) no-repeat center center;
}

.album {
    background: url(./dia.svg) no-repeat center center;
}
