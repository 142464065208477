
.overlay {
    position: absolute;
    border: none;
    color: #575757;
    background-color: rgba(255, 255,255, 0.5);
    border-bottom-right-radius: 4px;
    margin: 0;
}
.overlay:hover, .overlay:focus {
    color: #222222;
}

.uploaderItem {
    height: 200px;
    width: 200px;
    position: relative;
    margin: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.uploaderItem img {
    max-height: 200px;
    max-width: 200px;
}
.uploaderItem button {
    display: none;
    line-height: 30px;
    font-size: 30px;
    height: 40px;
    width: 40px;
    display:none;
}

.uploaderItem:hover button {
    display: block;
}

.uploader {
    padding: 0 8px 20px 0;
    min-height: 180px;
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 2px;
}

.itemsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.uploader > button {
    max-width: 150px;
    margin: 20px auto;
}

.dragover {
    background-color: #5E5E5E;
}

p.helper {
    margin: 20px auto;
    font-style: italic;
    text-align: center;
}

