.modalSection {
    box-sizing: border-box;
    max-width: 770px;
    margin: 80px auto;
    position: relative;
    background: var(--article-bg);
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    padding: 0;
}

.maxModalSection {
    box-sizing: border-box;
    margin: 80px 0;
    width: auto;
    position: relative;
    background: var(--article-bg);
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    padding: 0;
}

.modalMarginWrapper {
    margin: 0 2%;
}
