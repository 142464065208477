.wrapper {
    width: 30px;
    margin: 0 5px;
    height: 100%;
}

.removeButton, .removeButtonActive {
    width: 30px;
    height: 100%;
    text-decoration: none;
}

.removeButton {
    background: no-repeat url(./removeFromAlbum-inactive.svg) center center;
}

.removeButtonActive:hover, .removeButtonActive:focus, .removeButtonActive:active {
    background: no-repeat url(./removeFromAlbum-hover.svg) center center;
}

.removeButtonActive {
    background: no-repeat url(./removeFromAlbum.svg) center center;
}
