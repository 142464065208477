.rating {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.rating li {
    display: inline-flex;
    margin: 0;
    padding: 0;
    height: 25px;
    width: 25px;
    border: none;
}

.rating button {
    background-color: inherit;
    margin: 0;
    padding: 0;
    height: 25px;
    width: 25px;
    border: none;
    cursor: pointer;
}

.point {
    background-image: url('./point.svg');
}

.star {
    background-image: url('./star.svg');
}
