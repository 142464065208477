.modalHeader {
    padding: 30px 60px 20px;
    border-bottom: 1px solid #313131;
}

.modalHeader h1 {
    color: #000;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 800px) {
    /* We use less padding on small screens (phones) */
    .modalHeader {
        padding: 30px 10px 20px;
    }
}
