.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0,0,0, .2);
    z-index: 999;
}

.overlay ul {
    overflow: hidden;
    margin: 70px 15px;
    float: right;
    min-width: 250px;
    width: auto;
    background: var(--article-bg);
    border: solid 1px var(--article-border);
    box-shadow: 0 0 8px #000;
    border-radius: 4px;
    padding: 5px 0;
}

.overlay ul li a, .overlay ul li button {
    color: #000;
    display: block;
    padding: 0 15px;
    height: auto;
    width: 100%;
    background-color: var(--article-bg);
    font-size: .9em;
    cursor: pointer;
    text-align: left;
    line-height: 30px;
}

.overlay ul li a:hover, .overlay ul li a:focus, .overlay ul li button:hover, .overlay ul li button:focus {
    background-color: #585858;
    color: #000;
}

.overlay hr {
    margin: 6px 0;
    height: 1px;
    color: #444;
    border: none;
    background-color: #444;
}
