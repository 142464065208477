.cancel {
    position: absolute;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 30px;
    line-height: 3.0rem;
    font-weight: bold;
    height: 40px;
    width: 40px;
    vertical-align: top;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    right: 8px;
    top: 8px;
    color: var(--title-color);
}

.cancel:hover, .cancel:focus {
    color: #000;
}
