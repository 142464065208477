.userMenu {
    display: flex;
    align-items: center;
    width: auto;
    height: var(--header-height);
    color: var(--header-color);
    padding: 0 15px;
    background: none;
    position: relative;
    font-size: 17px;
    font-size: 1.7rem;
}

.userMenu:hover, .userMenu:active, .userMenu:focus {
    background-color: var(--trans-white); 
}

.userMenu img {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    box-shadow: 0 0 6px #000;
    margin-right: 12px;
    background: #666;
}

.wrapper {
    position: relative;
}

.userNames {
    margin-right: 20px;
}

@media screen and (max-width: 800px) {
    .userNames {
        display: none;
    }
    .userMenu img {
        margin-right: 0;
    }
}
