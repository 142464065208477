.navigation {
    height: 30px;
    color: var(--header-color);
    background: var(--header-bg);
    border-bottom: 1px solid #131313;
    border-top: 1px solid #131313;
    display: flex;
    align-items: center;
    top: 55px;
    left: 0px;
    right: 0px;
    position: fixed;
    z-index: 3;
    padding-right: 10px;
}

.link {
    background: no-repeat url(./arrow.svg) center center;
    width: 60px;
    height: 100%;
}

.link:hover, .link:focus, .link:active {
    background-image: url(./arrow-hover.svg);
}

.navigation h2 {
    font-size: 15px;
    font-size: 1.5rem;
    color: #fff;
    margin: 0;
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.count {
    margin: 0;
    margin-right: auto;
    padding-left: 12px;
    font-size: 12px;
    font-size: 1.2rem;
    color: #919191;
}
