.wrapper {
    border-bottom: 1px solid #313131;
}

.navigation, .disabledNavigation {
    height: 30px;
    border-bottom: 1px solid #313131;
    display: flex;
    align-items: center;
}

.list {
    min-height: 300px;
    padding: 10px 0;
}

.spacer {
    width: 60px;
}

.back {
    background: no-repeat url(./arrow.svg) center center;
    width: 60px;
    height: 100%;
    cursor: pointer;
}

.back:hover, .back:focus, .back:active {
    background: no-repeat url(./arrow-hover.svg) center center;
}

.navigation h2, .disabledNavigation h2 {
    font-size: 15px;
    font-size: 1.5rem;
    margin: 0 auto;
    color: #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.disabledNavigation h2 {
    color: #313131;
}

.folder, .disabledFolder, .album, .disabledAlbum, .deactivated, .selectedAlbum {
    display: flex; 
    align-items: center;
    cursor: default;
}

.folder:hover, .album:hover {
    background-color: #585858;
}

.folder h6, .disabledFolder h6, .album h6, .disabledAlbum h6,
.selectedAlbum h6 {
    display: inline-block;
    font-weight: normal;
    color: #000;
    margin: 0 5px;
    padding-right: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.disabledFolder h6, .disabledAlbum h6 {
    color: #313131;
}

.folderIcon, .disabledFolderIcon, .albumIcon, .disabledAlbumIcon {
    display: inline-block;
    height: 45px;
    width: 45px;
    margin-left: 15px;
    flex: none; /* necessary else it causes misalignements with long h6 */
}

.selectedAlbum {
    background-color: #919191;
}

.folderIcon {
    background: no-repeat url(./folder.svg) center center;
    background-size: 35px auto;
}

.disabledFolderIcon {
    background: no-repeat url(./disabled-folder.svg) center center;
    background-size: 35px auto;
}

.albumIcon, .disabledAlbumIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.albumIcon {
    background: no-repeat url(./album.svg) center center;
    background-size: 35px auto;
}

.disabledAlbumIcon {
    background: no-repeat url(./disabled-album.svg) center center;
    background-size: 35px auto;
}

.albumIcon img, .disabledAlbumIcon img {
    object-fit: cover;
    height: 30px;
    width: 30px;
    border-radius: 3px;
}

.checkbox {
    margin-left: auto;
    margin-right: 15px;
    display: inline-block;
    height: 45px;
    width: 45px;
    flex: none; /* necessary else it causes misalignements with long h6 */
    background: no-repeat url(./checkbox.svg) center center;
}

