.justifiedLightbox {
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
}

.wrapper {
  position: absolute;
}

.wrapper img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  display: block;
}

.wrapper img:hover {
  opacity: .8;
}
