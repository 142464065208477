.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    display: flex;
    color: var(--header-color);
    background: var(--header-bg);
    border-bottom: 1px solid #131313;
    z-index: 4;
}

.header a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.header a:hover, .header a:active, .header a:focus {
    background: var(--trans-white);
}

.title {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-size: 2.8rem;
    font-weight: normal;
    font-family: var(--pinpict);
    margin: 0;
    padding: 0 0 0 30px;
    height: var(--header-height);
    color: var(--header-color);
}

.title a:hover, .title a:active, .title a:focus {
    background: none;
}

.logo {
    height: 45px;
}

.photo {
    position: relative;
    left: -10px;
    top: -2px;
}


@media screen and (max-width: 800px) {
    .photo {
        display: none;
    }
    .title {
        padding-left: 10px;
    }
}
