.wrapper {
    width: 30px;
    margin: 0 5px;
    height: 100%;
}

.addButton, .addButtonActive {
    width: 30px;
    height: 100%;
    text-decoration: none;
}

.addButton {
    background: no-repeat url(./addToAlbum-inactive.svg) center center;
}

.addButtonActive:hover, .addButtonActive:focus, .addButtonActive:active {
    background: no-repeat url(./addToAlbum-hover.svg) center center;
}

.addButtonActive {
    background: no-repeat url(./addToAlbum.svg) center center;
}
