.wrapper {
  margin-top: 40px;
}
.keywordsList {
  margin-bottom: 40px;
}

.keywordItem {
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  margin: 5px 5px 0 0;
  border: 1px solid #1a1a1a;
  border-radius: 3px;
  background: #272727;
}

.removeButton {
  border: none;
  color: #a0a0a0;
  background: none;
  font-size: 22px;
  line-height: 20px;
  padding: 0;
  padding-left: 5px;
  margin: 0;
}

.removeButton:hover,
.removeButton:focus {
  color: #fff;
}

.wrapper label {
  display: block;
  margin-bottom: 10px;
}

.wrapper input {
  display: block;
  width: 100%;
}

.suggestions {
  padding: 10px 0;
  background: #272727;
  border: 1px solid #1a1a1a;
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.suggestions li {
  align-items: center;
  padding: 10px 20px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.selected {
  background: rgba(0, 0, 0, 0.15);
}

.suggestions li:hover,
.suggestions li:focus {
  background: rgba(0, 0, 0, 0.1);
}
