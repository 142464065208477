.modalContent {
    padding: 32px 60px 0px;
}

.modalContent img {
    max-width: 100%;
}

@media (max-width: 800px) {
    /* We use less padding on small screens (phones) */
    .modalContent {
        padding: 32px 10px 0px;
    }
}
