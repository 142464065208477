.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    z-index: 999;
    text-align: center;
}

.imageWrapper {
    display: inline-block;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 130px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lightbox picture img {
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 130px);
    width: auto;
    height: auto;
    margin: 0;
    display: block;
}

.prev, .next {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.prev {
    right: 50%;
    background: no-repeat url(./arrow-left.svg);
    background-position: left 15px center;
}

.next {
    left: 50%;
    background: no-repeat url(./arrow-right.svg);
    background-position: right 15px center;
}

.prev:hover {
    background-image: url(./arrow-left-hover.svg);
}

.next:hover {
    background-image: url(./arrow-right-hover.svg);
}

.close {
    display: block;
    position: fixed;
    text-decoration: none;
    top: 0;
    right: 0;
    height: 80px;
    width: 80px;
    text-align: right;
    color: #fff;
    background: none;
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 24px;
    line-height: 2.4rem;
    padding: 25px;
}

.close:after {
    content: "×";
    color: #919191;
}
.close:hover:after {
    color: #fff;
}

.fullScreen, .exitFullScreen {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 80px;
    width: 80px;
}

.fullScreen {
    background: no-repeat url(./fullscreen.svg) center center;
}

.exitFullScreen {
    background: no-repeat url(./exit-fullscreen.svg) center center;
}

.fullScreen:hover {
    background-image: url(./fullscreen-hover.svg);
}

.exitFullScreen:hover {
    background-image: url(./exit-fullscreen-hover.svg);
}
